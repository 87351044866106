<template>
<div>
     <carousel-3d :perspective="0" :space="460" :display="3" :controlsVisible="true">
        <slide :index="2" class="text">
            <img src="/images/food-4.jpg" alt="Snack">
        </slide>
        <slide :index="3" style="background-image: url(../images/food-1.jpg)" class="relative bg-center bg-no-repeat bg-cover text">
            <a href="https://tailwindcss.com" target="_blank" class="absolute left-0 right-0 block w-56 py-3 mx-auto font-semibold text-center text-white bg-yellow-500 rounded-2xl bannerCTA">Find Out More</a>
        </slide>
        <slide :index="0" class="text">
            <img src="/images/food-3.jpg" alt="Snack">
        </slide>
        <slide :index="1" class="text">
             <img src="/images/food-2.jpg" alt="Snack">
        </slide>
    </carousel-3d>
</div>  
</template>

<script>
import { Carousel3d, Slide } from 'vue-carousel-3d';

export default {
    components: {
        Carousel3d,
        Slide
    },
}
</script>

<style>
.text{border-width:0 !important;}
.bannerCTA{bottom: 20px;}

.prev, .next{text-align: center !important; background: #525252; border-radius: 30px; color: white !important; line-height: 43px !important; }
.prev{left: 30px !important;}
.next{right: 30px !important;}

@media (min-width: 1200px) {
.carousel-3d-container{width: 1040px !important;}
}
</style>