<template>
  <div class="mx-auto cusWidth">
    <div style="background-image: url(../images/bg.jpg)" class="relative bg-center bg-no-repeat bg-cover text">
      <div class="container py-4 mx-auto text-center">
        <img src="/images/logo.png" alt="The Chef's Cooking oil" class="w-64 mx-auto">
        <img src="/images/sub-logo.png" alt="Check Out The Chef's stories" class="px-12 mx-auto">
        
        <FocusCarousel/>
        
      </div>
    </div>

    <!-- Product -->
      <div class="container py-4 mx-auto font-sans text-center">
        <h1 class="text-xl font-bold text-yellow-800 lg:text-3xl">Our Chef Products</h1>
        <p class="w-full px-6 py-4 mx-auto text-sm font-semibold md:text-base md:px-0 md:w-4/5">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean et felis sed justo luctus placerat ut eu magna. 
          Sed turpis justo, finibus in dapibus vitae, accumsan sed velit. Morbi pharetra eget diam ac viverra. Sed tristique facilisis convallis.
        </p>
        
        <ul class="mx-auto text-center textgray">
            <li class="inline-block w-full mx-0 sm:w-auto"><button class="py-2 mx-2 my-1 text-base font-semibold bg-gray-300 rounded-md px-14 md:text-lg" @click="tab = 'tab-1'" :class="[ tab == 'tab-1' ? 'bg-yellow-500' : '']">Category 1 </button></li>
            <li class="inline-block w-full mx-0 sm:w-auto"><button class="py-2 mx-2 my-1 text-base font-semibold bg-gray-300 rounded-md px-14 md:text-lg" @click="tab = 'tab-2'" :class="[ tab == 'tab-2' ? 'bg-yellow-500' : '']">Category 2 </button></li>
            <li class="inline-block w-full mx-0 sm:w-auto"><button class="py-2 mx-2 my-1 text-base font-semibold bg-gray-300 rounded-md px-14 md:text-lg" @click="tab = 'tab-3'" :class="[ tab == 'tab-3' ? 'bg-yellow-500' : '']">Category 3 </button></li>
        </ul>

        <div>
          <div v-show="tab == 'tab-1'">
            <carousel :paginationEnabled="false" :autoplayTimeout="2500" :autoplay="true" :autoplayHoverPause="false" :loop="true" :navigationEnabled="true" :perPageCustom="[[0, 1], [768, 2], [1024, 4]]" class="">
              <slide v-for="(item, i) in proDuct1" :key="i">
                <div class="py-5 m-12 boxShadow">
                  <img :src="item.image" :alt="item.alt" class="mx-auto"/>
                  <p class="py-2">{{ item.alt }}</p>
                  <a href="https://tailwindcss.com" target="_blank" class="block py-1 mx-auto font-semibold text-white bg-yellow-500 w-28 rounded-2xl">Buy Now</a>
                </div>
              </slide>
            </carousel>
          </div>
          <div v-show="tab == 'tab-2'">
            <carousel :paginationEnabled="false" :autoplayTimeout="2500" :autoplay="true" :autoplayHoverPause="false" :loop="true" :navigationEnabled="true" :perPageCustom="[[0, 1], [768, 2], [1024, 4]]" class="">
              <slide v-for="(item, i) in proDuct2" :key="i">
                <div class="py-5 m-12 boxShadow">
                  <img :src="item.image" :alt="item.alt" class="mx-auto"/>
                  <p class="py-2">{{ item.alt }}</p>
                  <a href="https://tailwindcss.com" target="_blank" class="block py-1 mx-auto font-semibold text-white bg-yellow-500 w-28 rounded-2xl">Buy Now</a>
                </div>
              </slide>
            </carousel>
          </div>
          <div v-show="tab == 'tab-3'">
            <carousel :paginationEnabled="false" :autoplayTimeout="2500" :autoplay="true" :autoplayHoverPause="false" :loop="true" :navigationEnabled="true" :perPageCustom="[[0, 1], [768, 2], [1024, 4]]" class="">
              <slide v-for="(item, i) in proDuct3" :key="i">
                <div class="py-5 m-12 boxShadow">
                  <img :src="item.image" :alt="item.alt" class="mx-auto"/>
                  <p class="py-2">{{ item.alt }}</p>
                  <a href="https://tailwindcss.com" target="_blank" class="block py-1 mx-auto font-semibold text-white bg-yellow-500 w-28 rounded-2xl">Buy Now</a>
                </div>
              </slide>
            </carousel>
          </div>
        </div>

      </div>
  </div>
</template>

<script>
// @ is an alias to /src
import FocusCarousel from '@/components/FocusCarousel.vue'

export default {
  name: 'Home',
  components: {
    FocusCarousel
  },
  data(){
    return{
      tab: 'tab-2',
      proDuct1: [
        {image: '/images/product-1.png', alt: 'Cooking Oil 1'},
        {image: '/images/product-1.png', alt: 'Cooking Oil 2'},
        {image: '/images/product-1.png', alt: 'Cooking Oil 3'},
        {image: '/images/product-1.png', alt: 'Cooking Oil 4'},
        {image: '/images/product-1.png', alt: 'Cooking Oil 5'},
      ],
      proDuct2: [
        {image: '/images/product-1.png', alt: 'Vegetable Oil 1'},
        {image: '/images/product-1.png', alt: 'Vegetable Oil 2'},
        {image: '/images/product-1.png', alt: 'Vegetable Oil 3'},
        {image: '/images/product-1.png', alt: 'Vegetable Oil 4'},
        {image: '/images/product-1.png', alt: 'Vegetable Oil 5'},
      ],
      proDuct3: [
        {image: '/images/product-1.png', alt: 'Natural Oil 1'},
        {image: '/images/product-1.png', alt: 'Natural Oil 2'},
        {image: '/images/product-1.png', alt: 'Natural Oil 3'},
        {image: '/images/product-1.png', alt: 'Natural Oil 4'},
        {image: '/images/product-1.png', alt: 'Natural Oil 5'},
      ],
    }
  }
}
</script>

<style>
/* Shadow */
.boxShadow{box-shadow: 1px 1px 6px #888888;}

.VueCarousel-navigation-prev, .VueCarousel-navigation-next{background: #525252 !important; color: white !important; border-radius: 25px !important; height: 39px !important; width: 39px;}
.VueCarousel-navigation-prev{left: 50px !important;}
.VueCarousel-navigation-next{right: 50px !important;}

@media (min-width: 1920px) {
.cusWidth{max-width: 1920px;}
}
</style>
